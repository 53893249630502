/* Bootstrap variables*/
/* @import "bootstrap/dist/css/bootstrap.min.css"; */

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}

/* COLORS */
:root {

  /* BOOTSTRAP PRESETS */
  --toggleSVG: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgb(0, 0,0)%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");

  /*COLORS*/
  --green: #008938;
  --greenBright: rgb(67, 201, 123);
  --greenLite: rgba(0, 137, 56, 0.567);
  --yellow: #f0b400;
  --yellowLite: rgba(240, 180, 0, .4);
  --yellowLite2: rgba(255, 223, 126, 0.48);
  --bgWhite: #f8f9fa;
  /*from bootstrap*/
  --borderLiteColor: rgba(0, 0, 0, 0.567);

  /* TRANSITION */
  --transition1: all .3s ease-in;
  --transition2: all .7s ease-in-out;


  /* SHADOW */
  --shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  --shadow2: 4px 4px 7px rgba(0, 0, 0, 0.2);
  --shadow3: 0 5px 25px 0 rgba(0, 0, 0, .3);
  --niceShadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

  /* BORDER */
  --borderLite: .5px solid rgba(0, 0, 0, 0.567);
  --borderGreenLite: .5px solid rgba(0, 137, 56, 0.567);
  --borderGreen: .5px solid rgb(0, 137, 56);


  /* FONT-SIZING */
  --navLinkSize: 1.75rem;
  --headerPara: min(7vw, 1.2em);

  --mainPara: min(7vw, 1.15em);
  --mainParaSmall: min(7vw, 1.1em);
  --mainParaSmallMob: min(7vw, 1em);


  --priceSize: min(7vw, 1.3em);

  /* FONT COLOR */
  --opaqueColor: rgba(0, 0, 0, 0.562);

  /* FONT-FAMILY */
  --roboto: 'Roboto', sans-serif;
  --yk: 'Yanone Kaffeesatz', sans-serif;
}


/* FONT REFERENCE */
/* ROBOTO : (font-family: 'Roboto', sans-serif !important; ) 
 100, 300, 400, 700, 900 
 */
/* Yanone Kaffeesatz : (font-family: 'Yanone Kaffeesatz', sans-serif; ) 200,300, 400, 700 
 */

body {
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  background-color: var(--bgWhite) !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

}

.container-fluid {
  padding: .5rem !important;
}

@media(min-width:992px) {
  .container-fluid {
    padding: 0 !important;
  }
}

.brand {
  display: flex;

}

/* BS class */
.navbar-brand {
  margin-right: 0 !important;
  margin-left: 7% !important;
  display: flex !important;
  align-items: center !important;
  padding-top: 0 !important;
}

/* navigation logo */
.logo {
  max-height: 3rem;
  margin-right: .25rem;
}

/**** FOOTER ******/
.footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding: 2rem 7% 2.5rem 7%;
  width: 100% !important;
  border-top: 1px solid var(--borderLiteColor)
}

.footer_title {
  display: flex;
  align-items: center;
  color: var(--green);
  color: black;
}

.footer_left {
  margin-bottom: 2rem;
}

.footer_right {
  height: min-content;
}

.logo_footer {
  max-width: min(6rem, 100%);
  max-height: 6rem;
  margin-bottom: .25rem;
  margin-right: .25rem;

}

.connect_box {
  display: flex;
  flex-direction: column;
}

@keyframes asccordionAnimation {
  0% {}

  100% {
    transform-origin: center;
    transform: rotate(180deg);
  }
}

.rotate-minus {
  animation: asccordionAnimation .5s ease-out forwards;
}

.rotate-plus {
  animation: asccordionAnimation .5s ease-out reverse forwards;
}

.icon.active {
  animation-iteration-count: 1;
}




@keyframes locationAnimation {
  0% {}

  50% {
    transform-origin: center;
    transform: rotate(-60deg);
  }

  100% {
    transform-origin: center;
    transform: rotate(360deg);
  }
}




/* Footer icon animation */
@keyframes iconAnimation {
  0% {
    transform: scale(1);
    transform-origin: center;
  }

  12.5% {
    /* transform: scale(1.25); */
    transform-origin: center;
    transform: rotate(10deg);
  }

  25% {
    /* transform: scale(1.5); */
    transform-origin: center;
    transform: rotate(20deg);
  }

  37.5% {
    /* transform: scale(1.75); */
    transform-origin: center;
    transform: rotate(10deg);
  }

  50% {
    transform: scale(2.5);
    transform-origin: center;
    transform: rotate(0deg);
  }

  62.5% {
    /* transform: scale(1.75); */
    transform-origin: center;
    transform: rotate(-10deg);
  }

  75% {
    /* transform: scale(1.5); */
    transform-origin: center;
    transform: rotate(-20deg);
  }

  87.5% {
    /* transform: scale(1.25); */
    transform-origin: center;
    transform: rotate(-10deg);
  }

  100% {
    transform: scale(1);
    transform-origin: center;
    transform: rotate(0deg);
  }
}





.dropdown-item {
  color: var(--bs-nav-link-color) !important;
}

.dropdown-item:hover,
.dropdown-item:active {
  color: black !important;
}


.footer_link {
  transition: transform 0.4s ease-in-out;
}

.footer_link:active {
  transform: scale(.75);
}


.connect_box_links {
  display: flex;
}

.instagram_link {
  margin-left: 5%;
  margin-right: 5%;
}

.quickLink_list_container {
  display: flex;
  flex-direction: column;

  margin-bottom: 1rem;
  min-width: max-content;
}

.quickLink_list {
  margin: 0 !important;
  list-style: none;
  padding-left: 0;
}

.socialMedia_icon {
  height: 2.25rem;
}

.quickLink_list li:not(:last-child) {
  margin-bottom: .5rem;
}

.footer_quickLinks,
.contactBox_links {
  font-family: var(--yk);
  text-decoration: none;
  color: var(--bs-heading-color);
  font-weight: 500;
  font-size: 1.25rem;
}

.footer_quickLinks:hover{
  color:rgb(65, 195, 65)
}

.quickLink_img,
.contact_img {
  height: 2rem;
  margin-right: .5rem;
}

.contact_box {
  display: flex;
  flex-direction: column;
  min-width: max-content;
}


.contactBox_links_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contactBox_links:first-child {
  margin-bottom: .5rem;
}

.phone_img {
  margin: 0 1rem 0 .5rem;
}




.call-us-button {
  position: fixed;
  top: 75px;
  /* Adjust as needed */
  right: 20px;
  /* Adjust as needed */
  z-index: 100;
  /* Ensure it stays above other elements */
  padding: 10px 20px;


  /* Example background color */
  color: white;
  border: none !important;
  border-radius: 5px;
  cursor: pointer;
  transition: var(--transition1);

}

@keyframes waveText {

  0%,
  100% {
    transform: translateX(0) scale(1);
  }

  50% {
    transform: scale(1.1);
    /* color: black; */
    /* Adjust this value as needed */
  }
}

/* the problem is that, for */

/* 

I transform-scaled down the .call-us-button & the .simp-chat-container to .8, then @450 transform scaled to 'none', which is overriding the animation scaling. 

Instead of transorm scaling these down, I need to reset their css to be smaller here and larger at 150.

The button's 

for the button, you'd just reset them to 'unset' @450, since its a bootstrap button, it'll go back to 'full'/default. 
*/

.call-us-button,
.simp-chat-container {
  background-color: var(--yellow) !important;
  box-shadow: var(--shadow);
  transform-origin: center;
  border: 3px solid transparent !important;
  animation: waveText 3.5s infinite linear !important;

  right: 1rem !important;
  /* <-- CHANGE THIS*/
  transition: var(--transition1) !important;
}

.call-us-button {
  border: 3px solid transparent !important;
  /* CHANGE THESE */
  padding: .15rem .25rem !important;
  margin-top: .5rem;
}

.call-us-button>a {
  /* CHANGE THIS */
  font-size: 1.5rem !important;
}

.call-us-button>svg {
  height: .75em !important;
  width: .75em !important;
}




.simp-chat-popup {
  background-color: black !important;
  box-shadow: var(--shadow) !important;
  bottom: 2rem !important;
  right: 5% !important;
  background-image: url(images/simp_bg.webp);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  transition: var(--transition1) !important;
  height: 84vh;
  max-height: 950px;
  overflow: scroll;

}


/* see also line 1395 for .simp-chat-container */


.call-us-button:hover,
.simp-chat-container:hover {
  box-shadow: var(--shadow);
  /* animation: waveText 3.5s infinite linear; */
  transform: scale(1.1);
  animation-play-state: paused;
  border: 3px solid white !important;
  color: white !important;
  background-color: var(--green) !important;
  cursor: pointer;
}

.simp-popup-header>a {
  background-color: red;
  font-size: 1.5em !important;
  padding: 0 10px !important;
  border-radius: 100%;
  border: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.simp-popup-header>a:hover {
  background-color: rgb(186, 3, 3);
}


.simp-step-2>a {
  margin-top: 2rem !important;
}


.animated-text {
  display: inline-block;
}


.call-us-button:active {
  transform: scale(1);
}

.btn {
  font-family: 'Yanone Kaffeesatz', sans-serif !important;
  font-weight: 900 !important;
  font-size: 2em !important;
}



p {
  letter-spacing: -1px;
  font-weight: 400;
  /* font-size: var(--mainPara); */
  font-size: var(--mainParaSmallMob);
}



.navbar-brand {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-weight: 900;
  font-size: 2.25em !important;
  color: var(--yellow) !important;
}

.navbar-toggler {
  /* Bootstrap variable alterations */
  --bs-navbar-toggler-padding-x: .15rem;
  --bs-navbar-toggler-padding-y: .15rem;
  border-color: black !important;
  ;
  box-shadow: none !important;
  border-width: 2px !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar-toggler-icon {
  background-image: var(--toggleSVG) !important;
}


#navbar_id {
  z-index: 150;
  /* to put over 'Locations' .collapse-box*/
  position: fixed !important;
  ;
  /* padding-left:6% !important; */
  top: 0;
  border-bottom: var(--borderLite);
}

.nav-link {
  display: flex !important;
  align-items: center !important;
  font-family: var(--yk);
  font-weight: 900 !important;
  font-size: var(--navLinkSize) !important;

  justify-content: flex-start !important;
  padding-left: 0px !important;
}

.nav-link:hover {
  cursor: pointer;
}

.home-link {
  margin-top: .5rem !important;
}

@media(min-width:992px) {
  .home-link {
    margin-top: 0 !important;
  }
}

/* 'locations' dropdown links */
.nav-item {
  /* color:var(--green)!important; */
  font-family: var(--yk) !important;
  ;
  font-size: var(--navLinkSize);
  font-weight: 900 !important;
}

.nav-link:hover,
.nav-item:hover {
  cursor: pointer;
  background-color: var(--yellowLite) !important;
}

.dropdown-toggle {
  padding: 1rem !important;
}

.dropdown-toggle::after {
  margin-left: .75rem !important;
}

.locations::after {
  display: inline-block;
  margin-left: .75rem;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}


.navbar-collapse {
  margin-bottom: 1rem;
  margin-bottom: 0;
  background-color: var(--bgWhite);
  padding: 0;
}

.collapse-box {
  width: 100%;
  left: 0rem;
  margin-top: 100%;
  margin-top: var(--bs-dropdown-spacer);
}


/* Bootstrap cut-off for dropdown */

/**** DESKTOP ******/

/************ ALL PAGES **********/
.btn-primary {
  background-color: var(--yellow) !important;
  border: 3px solid transparent !important;
  transition: color .15s ease, transform .15s ease, box-shadow .15s ease, background-color .15s ease, border-color .15s ease !important;
}

.btn-primary:not(.show_btn):active {
  transform: scale(.95) !important;
  background-color: var(--green) !important;
}

/********* HEADER SETTINGS ***********/

header {

  padding: 27% 7% 15% 7%;
  margin-top: 69px;
  color: var(--bgWhite) !important;

  /* INDENTATION */
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 50% 98%, 0% 100%);
  width: 100%;

  /* Image */
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: left; */
  background-position: 40% 25%;
  box-shadow: var(--shadow3) !important;
}

h1 {
  display: inline-block;

  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-weight: 900 !important;
  font-size: calc(5rem + 1.5vw) !important;
  line-height: .7 !important;
  letter-spacing: 1px !important;
}

h2 {
  font-size: calc(2rem + 1.5vw) !important;
  font-weight: 900 !important;
  letter-spacing: -2px !important;
  line-height: 1.1 !important;
}

h2.header_h2 {
  font-weight: 300 !important;
}

.footer h2 {
  font-family: var(--yk);
  margin-bottom: 0;
  font-size: 3em !important;
  letter-spacing: -1px !important;
  margin-left: -1rem;
}

h3 {
  font-family: 'Yanone Kaffeesatz', sans-serif !important;
  font-size: 2.5em !important;
  font-weight: 900 !important;
  letter-spacing: -1px !important;
  line-height: 1 !important;

}



legend {
  font-size: 1.25em !important;
}

legend h3 {
  font-size: 2em !important;
}

.service_box_right {
  align-self: flex-start;
}

.service_box_right h3 {
  font-family: var(--yk);
  font-weight: 900 !important;
  font-size: 2.25rem !important;
}

.text-decoration-underline {
  font-weight: 400;
}

.header_para {
  /* line-height:1.75rem; */
  margin-top: min(7%, 1.75rem) !important;
  /* max-width: 500px; */
}

.header_list {
  margin: .75rem 0 .75rem 1rem;
  /* line-height:1.5; */
}

.header_list li {

  list-style-type: none;
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: 3;
  gap: 4px;
}

.header_list strong {
  font-weight: 900;
  font-size: min(7vw, 2em);
  color: var(--yellow);
  /* letter-spacing:1px; */
  font-family: var(--yk);
  font-weight: 700 !important;
  line-height: .8 !important;
}

.header_list span {
  display: flex;
  align-items: center;
  line-height: 1.25;
}

/**** HEADER: HOME *****/

.header_home {
  background-image: url(images/home_header.webp);
}



.header_para,
.header_btn {
  margin-left: min(3%, 1rem) !important;
}

/**** HEADER: ABOUT US *****/

.header_aboutUs {
  background-image: url(images/aboutUs_header.webp);
}

/**** HEADER: APPLY *****/

.header_apply {
  background-image: url(images/apply_header.webp);

}

.h1_apply {
  line-height: 1 !important;
}


@media(min-width:507px) {
  .h1_apply {
    line-height: .7 !important;
  }
}



/*********** MAIN SETTINGS ************/

main {
  display: grid;
  grid-template-columns: 1fr;
  /* position:relative; */
}


main p,
.footer p {
  color: var(--opaqueColor)
}

main>*:not(button, .row_4_home, .row_1_aboutUs, .row_1_dashboard) {
  margin-bottom: 4rem;
  /* grid with 1 row, 3 columns, where col's 1 & 3 are 6% width */
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 7% 1fr 7%;
}

/* will need to select all 'middle' divs in each grid row and assign display:flex */

.main_1_home_mobile,
.main_1_home_desktop,
.main_2_home,
.main_3_home,
.main_4_home,
.main_6_home {
  display: flex;
}





.dashboard {
  display: flex !important;
  background-color: pink;
  /* justify-content: center; */
  /* border:2px solid red; */
}


/**** MAIN: HOME ****/
.img_main_home {
  width: min(100%, 600px);
}

/* text box before & after 1050 breakpoint */
.text_main_1_home {
  background-color: var(--bgWhite);
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, .3);
  padding: 2rem;
  height: min-content;
}

/**** MAIN: MAIN_1_HOME ****/

.main_1_home_mobile {
  flex-direction: column;
  align-items: center;
}

.main_1_home_desktop {
  flex-direction: row;
  justify-content: center;
}

.transform {
  margin-top: 2rem;
  margin-left: -2rem
}

.highlight_yellow {
  color: var(--yellow);
}

/**** MAIN: MAIN_2_HOME ****/

.main_2_home,
.main_3_home {
  flex-direction: column;
  align-items: center;
}

.highlight_green {
  color: var(--green);
}

.main_2_home_title {
  text-align: center;
  margin-bottom: 3vw;
}

.main_3_home_title {
  min-width: 100%;
  margin-bottom: 3vw;
}

.main_3_home_title h2 {
  text-align: center;
}

.main_2_home_content {
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.step {
  background-color: var(--bgWhite);

  /* height:275px; */
}

.step p {
  margin-top: .75rem;
}

.step_title {
  text-align: center;
  margin-bottom: 1rem;

}

.step:hover {
  background-color: rgba(250, 250, 250, 0.941);
  ;
}

.icon {
  color: var(--green);
  /* font-size:5em; */
}

/* card */
.my_card {
  border: var(--borderLite);
  display: flex;
  flex-direction: column;
  max-width: 275px;
  padding: 1.3rem .75rem .75rem .75rem;
  transition: transform 0.12s ease;
  /* background-color:white; */
  /* border: 2px solid blue; */
}

.my_card:hover {
  cursor: pointer;
}

.my_card p {
  font-size: .9rem;
  margin-bottom: 0 !important;
}

/**** MAIN: MAIN_3_HOME ****/

.main_3_home_title {
  align-self: flex-start;
}


.main_3_home_content {
  /* border: 1px solid red; */
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 1rem;

}

h3.reach_out {
  margin-top: 3rem;
  font-size: 3em !important;
  color: var(--green) !important;
}

h3 .year {
  font-size: 1.15em;
}

/* my_card */
.car {
  padding-bottom: 1.25rem;
}

.car_content {
  color: var(--opaqueColor);
  padding: 1rem 1rem 1rem 1.8rem;
  width: 85%;
  align-self: center;
  border: var(--borderLite);
}

.car_price {
  display: flex;
  align-items: center;
  color: black;
  font-weight: 900;
  font-size: var(--priceSize);
  margin-bottom: 0;
}

.car_price>strong {
  font-family: var(--yk);
  font-size: 2rem;
  margin-right: 5px;
}

.car_list {
  font-size: var(--mainParaSmallMob);
  font-weight: 300;
  margin-left: -10px;
}

.car_list li {
  line-height: 1.25;
}

.car_btn {
  background-color: var(--green) !important;
  transition: 0.2s ease-out;
  transform-origin: center;
}

.car_btn:active {
  background-color: var(--yellow) !important;
  color: black !important;
}

/**** MAIN: MAIN_4_HOME ****/

.main_4_home {
  padding: 4rem 7%;
  background-image: url('images/highway.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  justify-content: center;
}

.main_4_home h2 {
  line-height: 1.1;
  font-size: 2.5rem !important;
  font-weight: 400 !important;
  letter-spacing: -2px !important;
}

.main_4_home_content {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img_main_4_home {
  display: flex;
  align-items: center;
  justify-content: center;
}

.service_img {
  height: min(100%, 600px);
  width: min(100%, 600px);
}

.text_main_4_home {
  background-color: var(--bgWhite);
  background-image: url('images/headphones.webp');
  background-position: 0% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, .3);
  padding: 1.5rem;
  margin-top: 2rem;
  max-width: 350px;
}

.service_icon {
  margin-bottom: 1rem;
}

.service_para {
  margin-left: min(3%, 1rem) !important;
  margin-bottom: 0;

}

/******** SERVICE GRID ********/

.service_grid {
  display: grid;
  justify-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.service_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;


}

.service_box>* {
  text-align: center;
}

.service_box p {
  margin-bottom: 0 !important;
  font-size: .9rem;
}

/**** MAIN: MAIN_5_HOME ****/

.faq_grid_container {
  display: flex;
  flex-direction: column;
}

.faq_grid {
  display: grid !important;
  grid-gap: 1rem !important;
  grid-auto-rows: min-content;
  align-items: start;
}

.faq_grid_1 {
  margin-bottom: 1rem;
}

.accordion-item {
  border: var(--borderLite) !important;
}

.accordion-button:hover {
  background-color: var(--yellowLite2) !important;
}

.accordion-button {
  font-family: var(--roboto) !important;
  font-weight: 700 !important;
}

.accordion-button:not(.collapsed) {
  background-color: var(--yellowLite) !important;
}

.accordion-button:focus {
  border-color: none !important;
  box-shadow: none !important;
}

.accordion-body {
  font-family: var(--roboto) !important;
  color: var(--opaqueColor) !important;
}

.accordion_icon {
  color: var(--yellow);
  margin: 0 1rem 0 0;
}

.accordion-button:active .accordion_icon {
  animation: iconAnimation .5s
    /*cubic-bezier(.5, 1.4, .56, -0.7)*/
    ease-in-out;
  transform-origin: center;
}

/**** MAIN: MAIN_6_HOME ****/
.main_6_home {
  flex-direction: column;
}

.main_6_home h2 {
  text-align: center;
}

.main_6_home h3 {
  align-self: center;
  margin-bottom: 0;
}

.locations_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.location {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;
  padding: 2rem 2rem 0rem 2rem;
  padding: 0;
}

.location p {
  align-self: center;
  margin: 0 1rem;
  text-align: center;
  text-indent: min(7%, 35px);
}

.img_location {
  max-width: 80%;
  background-color: var(--greenBright);
  border-radius: 50%;
  box-shadow: var(--niceShadow);
  transition: var(--transition2);


}

.img_location:hover {
  box-shadow: inset var(--shadow3);
  transform: scale(.95)
}

/* .img_location:hover  */



/**** MAIN: MAIN_1_ABOUTUS ****/

.row_1_aboutUs {
  /* border:4px solid black; */
  height: min-content !important;



}

.main_1_aboutUs {
  /* margin-top:4.25rem; */
  /* padding-top:15rem; */
  background: linear-gradient(170deg, #2c486f, #446ca5, #829e93 99.99%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: 7px solid orange; */

  min-height: max-content !important;
  overflow: hidden !important;
}

.main_1_aboutUs>* {
  /* border:7px solid aqua; */
}

.aboutUs_title {
  padding: 11rem 7% 0 7%;
  font-size: calc(5rem + 1.5vw) !important;
  text-shadow: var(--shadow);
  line-height: .9 !important;
  /* margin-bottom:vw; */
}

.img_para_box {
  display: flex;
  flex-direction: column;
}

.aboutUs_para {
  color: white;
  text-indent: min(7%, 35px);
  margin-top: 2rem;
  ;
  margin-bottom: 0;
  padding: 0 7%;
  /* flex:1; */
  /* line-height:2.5vw; */

}

.aboutUs_img_wrapper {
  max-width: 25%;
  overflow: hidden;
  align-self: flex-end;
  display: flex;
  align-items: flex-end;

}

.aboutUs_img {
  height: auto;
  width: 100%;
  -webkit-clip-path: polygon(44% 4%, 53% 0, 100% 0, 100% 100%, 28% 100%, 0 81%, 8% 57%, 19% 49%, 17% 29%, 37% 24%);
  clip-path: polygon(44% 4%, 53% 0, 100% 0, 100% 100%, 28% 100%, 0 81%, 8% 57%, 19% 49%, 17% 29%, 37% 24%);
  /* max-width:30%; */
}

/**** MAIN: applyApp ****/

.main_apply {
  margin-top: -10rem;
  padding-top: calc(5vw + 7rem);
  /* padding-bottom:10rem; */
  /* background-image: url('images/apply_bg4.webp');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top; */

  background: linear-gradient(270deg, #f0b40000, #f0b40039 99.99%);


}

.applyBox {

  display: flex;
  flex-direction: column;
  align-items: center;
  /* color: white !important; */
  font-family: var(--yk) !important;
  letter-spacing: 1px;
  ;

}

.applyBox>h2 {
  letter-spacing: 0 !important;
  ;
}

.applyBox>form>label {
  border: 1px solid blue !important;

}





.form-label,
h4 {
  font-size: 1.25rem !important;
  margin-left: .25rem;
  margin-bottom: .25rem !important;
}

#ssn,
#dl {
  max-width: 400px;
}

.form-control,
.mask {
  max-width: 600px;
  border: 1px solid black !important;

}

.form-control:focus,
.form-control:focus-within,
.mask:focus-visible,
.react-datepicker__input-container:focus-visible {
  box-shadow: none !important;
  /* border: var(--bs-border-width) solid var(--bs-border-color) !important; */
  border: 1px solid black !important;
}

.form-check {
  margin-right: 1rem;
  display: flex !important;
  align-items: center !important;

}

.form-check-input {
  transform: translateY(-5%) !important;
  padding: 20px;
  border-radius: 50% !important;
  border: 1px solid black !important;
}

.form-check-input:hover {
  cursor: pointer;
  /* background-color:var(--greenLite) */
}

.form-check-input:focus,
.form-check-input:checked {
  box-shadow: none !important;
  border: 1px solid black !important;
}

.form-check-input:checked[type=checkbox] {
  background-color: var(--bs-body-bg) !important;
  --bs-form-check-bg-image: url("icons/car-filled.svg") !important;
  background-size: 80%;
}

/* browser 'required field' message */
.text-muted {
  --bs-secondary-color: red;
}



.form-check-label {
  margin-left: .5rem;
  font-size: 1.3rem;
}

.password_wrapper,
.birthday_wrapper,
.ssn_wrapper,
.dl_wrapper,
.gigEmp {
  display: flex;
  align-items: center;
}

.show_btn {
  height: 100%;
  display: flex !important;
  align-items: center !important;
  /* margin-left: .5rem; */
  background-color: transparent !important;
}

.show_btn svg {
  /* fill: rgb(255, 255, 255) !important; */
  fill: black;
  transition: var(--transition1);
}

.show_btn:hover svg {
  fill: var(--yellow) !important;
}

.mask {
  text-security: disc;
  -webkit-text-security: disc;
}

.react-datepicker {
  background-color: white;
}

.react-datepicker__input-container input {
  height: 44.2px !important;
  padding-left: 12px;
  border-radius: .375rem;
  border: 1px solid var(--bs-border-color);
}

.submit_box {
  display: flex;
  flex-direction: column;
  padding-top: 1rem
}

.submit_box .btn {
  max-width: max-content;
  ;
}

.submit_box .btn:hover {
  border: 3px solid white !important;
}

#smsTextLabel {
  font-size: 1.2rem !important;
  max-width: 400px;
}




/* DASHBOARD after user logs in/fills out an applicaiton*/

.page_content_dashboard {
  /* border:3px solid black; */
  margin-top: 4.25rem;
  min-height: calc(100vh - 4.25rem);
  background-image: url('images/keys.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}


.dashboard_main {
  min-height: calc(100vh - 4.25rem);

  /* border:3px solid purple; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.row_1_dashboard {
  min-width: 300px;
  /* border:1px solid blue; */
}



.main_1_dashboard {
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: rgba(var(--bgWhite), 0.7);
  background-color: rgba(255, 255, 255, 0.885);
  /* max-width: 380px; */

}

.main_1_dashboard h2 {
  text-align: center;
}

.main_1_dashboard p {
  margin-top: 1.5rem;
  font-size: 1rem;
  text-align: center;
  ;
}


/*********************  Simp Chat Container **********************/

.simp-chat-container {
  /* padding: .25rem !important;  */
  padding: .15rem !important;
  /* CHANGE THIS*/
  justify-content: space-around;
  line-height: 2;
  font-size: var(--mainParaSmall);
  background-color: var(--yellow) !important;
  bottom: 10px !important;
  /* transition:var(--transition1) !important; */
}

.simp-chat-container>a>img {
  /* width:3rem !important; */
  width: 2rem !important;
  /* CHANGE THIS*/
  text-shadow: var(--shadow);
}

.simp-open-button-container {
  border-radius: 23.5px !important;
  box-shadow: var(--shadow);
}


.simp-chat-popup {
  padding: 1.5rem !important;
  padding-top: 2rem !important;
}

.simp-btn {
  background-color: var(--yellow) !important;
  transition: var(--transition1) !important;
}

.simp-btn:hover {
  background-color: var(--greenBright) !important;
}

.simpField {
  padding: 15px !important;
  border-radius: 4px !important;
}

.simp-note {
  font-size: 1rem !important;
  letter-spacing: -.5px;
}

#input-terms {
  width: 2em;
  height: 2em;
}

label[for="simp-service"],
label[for="simp-sales"] {
  font-size: 2.25rem !important;
  font-weight: 700 !important;
  margin-left: 1rem;
  font-family: var(--yk) !important
}

.simp-type-options>input[type="radio"] {
  /* height: 2rem !important;
  width: 2rem !important; */
  /* font-size:3rem !important; */

  border: 1px solid aqua !important;
  padding: 10px !important;

}

#simp-sales {
  /* display:none; */
  padding: 10px !important;
}

/*  TOS & PRIV POL */
.main_content h3{
  margin-top:.5rem;
}



/* animation bringing in 'Text Us' letters one by one */



/*********** BREAKPOINTS (450, 650, 850, 1050, 1300 **********/

/* 450px */

@media(min-width:450px) {

  /***** HOME *****/
  p {
    font-size: var(--mainPara);
  }

  header {
    padding: 7rem 7% 8% 7%;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 50% 95%, 0% 100%);
  }

  .header_list {
    margin: 1.5rem 0 1rem 1.5rem;
    /* line-height:1.5; */
  }

  /* .header_list li{
    gap:.5rem;
  } */

  .header_list>li>strong {
    line-height: 1
  }

  .car_btn:hover {
    background-color: var(--yellow) !important;
    color: black !important;
  }

  .car_price>strong {
    font-size: 2em;
  }

  .btn-primary:not(.show_btn):hover {
    background-color: var(--green) !important;
  }

  .my_card {
    max-width: 300px;
  }

  .my_card:hover {
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, .3);
    border: var(--borderLite);
    transform: scale(1.03);
  }

  .my_card p {
    font-size: var(--mainParaSmall)
  }

  .my_card:active {
    transform: none;
  }

  .text_main_4_home {
    padding: 2.5rem;
    max-width: unset;
  }

  .service_box {
    flex-direction: row;
  }

  .service_box>* {
    text-align: unset;
  }

  .service_box p {
    font-size: var(--mainParaSmall);
  }

  .service_box_left {
    padding-right: 1.5rem;
  }

  .footer_title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .logo_footer {
    max-width: min(8rem, 100%);
    max-height: 8rem;
    /* margin-bottom: 1rem; */
    margin-right: .5rem;
  }

  .row_1_dashboard {
    max-width: 60%;
    /* border:7px solid orange; */
  }


  .call-us-button {

    padding: unset;
    right: 1rem !important;
  }

  .call-us-button>a {
    /* CHANGE THIS */
    font-size: unset !important;
  }

  .simp-chat-container {
    right: 20px !important;
    font-size: var(--mainPara) !important;
    bottom: 23.5px !important;
  }


  .simp-chat-container {
    padding: .25rem !important;

  }

  .simp-chat-container>a>img {
    width: 3rem !important;

  }



  /* <-- CHANGE THIS*/




}


/* 650px */
@media(min-width: 650px) {

  /***** HOME *****/

  .main_2_home_content {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem;
  }

  .car {
    padding: 1.5rem;
    max-width: 350px;
  }

  .car_list {
    font-size: 1.25rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .my_card {
    padding: 1.25rem;
    transform: none;
    max-width: 356px;
  }

  .step {
    height: 100%;
  }

  .location {
    max-width: 300px;
  }

  header {
    background-position: 35% 40%;
  }

  .simp-chat-container {
    min-width: 355px !important;
  }


}

/* 850px */

@media(min-width:850px) {

  /***** HOME *****/

  .navbar-nav {
    width: 100% !important;
    justify-content: space-evenly !important;
  }

  .locations {
    position: relative;
  }

  .collapse-box {
    position: absolute;
    width: 12rem;
    left: 0rem;
    z-index: 3;
    top: 100%;
    background-color: var(--bgWhite);
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: var(--borderLiteColor);
  }

  .main_3_home_content {
    grid-template-columns: repeat(2, 1fr);
    gap: 5vw;
  }

  .car {
    height: 100%;
  }

  .car_content {
    padding-left: 3rem;
  }

  .locations_container {
    flex-direction: row;
    justify-content: center;
    /* align-items: flex-start; */
    /* padding-left: 2rem; */
  }

  .location_atlanta {
    margin-right: 4%;
  }

  .location_chicago {
    margin-left: 4%;
  }

  .img_location:hover {
    animation: locationAnimation .7s
      /*cubic-bezier(.5, 1.4, .56, -0.7)*/
      ease-in-out;
    transform-origin: center;
  }

  .service_grid {
    grid-template-columns: repeat(2, 1fr);
    align-content: center;
    gap: 1.25rem !important;
  }

  .service_box {
    max-width: 375px;
  }

  .footer_link:hover .footer_icon {
    animation: iconAnimation .4s ease-in-out;
    transform-origin: center;
  }

  /* Apply Form */
  .applyBox form {
    /* border: 2px solid blue; */
    margin-left: 5%;
  }

}

/* 1050px */

@media(min-width:1050px) {

  /***** HOME *****/

  .header_para {
    max-width: 75%
  }

  .img_main_home {
    align-self: center;
    width: min(50%, 700px) !important;
  }

  .text_main_1_home {
    padding: 2rem;
  }

  .text_main_1_home p {
    font-size: var(--mainParaSmall) !important
  }

  .faq_grid_container {
    flex-direction: row;
    justify-content: space-between;
  }

  .faq_grid_1 {
    margin: 0;
    margin-right: 1rem;
  }

  .faq_grid_1,
  .faq_grid_2 {
    width: calc(50% - .5rem);
  }

  .footer {
    flex-direction: row;
    /* padding:4rem 8%; */
  }

  .footer_left {
    max-width: 50%;
    margin-right: 12%;
    margin-bottom: 0;
  }

  .footer_right {
    display: flex;
    margin-top: 2rem;
  }

  .quickLink_list_container {
    margin-right: 3rem;
    margin-bottom: 0;
  }


  /******** ABOUTUS ***********/

  .main_1_aboutUs {
    /* height:100vh!important; */
    min-height: 100% !important;
  }

  .img_para_box {
    flex-direction: row;
  }

  .aboutUs_para {
    margin-top: 3vw;
    margin-bottom: 8rem;
    padding: 0;
    padding-left: 7%;
    flex: 1;
    line-height: 2.5vw;

  }

  .aboutUs_img_wrapper {
    max-width: 25%;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
  }

  .aboutUs_img {
    max-height: 550px;
  }

  .aboutUs_title {
    padding: 11rem 7% 0 7%;
  }
}

/* 1300 */

@media(min-width:1300px) {

  /***** HOME *****/

  .row_1_home {
    margin-top: 4rem;
  }

  .transform {
    margin-top: 3rem;
    margin-left: -3rem
  }

  .text_main_1_home {
    width: min(100%, 650px);
    padding: 3rem;
  }

  .main_2_home_content {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.25rem;
  }


  .main_4_home {
    padding: 6rem 7%;
  }



  .service_img {
    box-shadow: -25px 20px var(--yellow);
    margin-left: 25px;
    margin-right: 3%;
  }

  .text_main_4_home {
    min-width: 55%;
    padding: 2.5rem;
    margin-left: 3%;
  }

  .service_grid {
    gap: 0;
  }

  .service_box p {
    margin-bottom: unset;
  }

  .service_box_right h3 {
    /* display: flex;
    align-items: center; */
    height: min-content;
  }
}

/* 1447px */
@media(min-width:1447px) {

  /***** HOME *****/
  .main_4_home_content {

    flex-direction: row;
    justify-content: space-between;
  }

  .service_img {
    width: 70vw
  }
}
.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;

    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
